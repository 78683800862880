 .join {
    display: flex;
    padding: 0 2rem;
    gap: 10rem;
 }

 .left-j {
    color:white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
 }

 .left-j>hr {
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%; margin: -10px 0;
 }

 @media screen and (max-width:768px){
   .join {
      flex-direction: column;
      gap:1rem;
   }

   .left-j {
      font-size: x-large;
      flex-direction: column;
   }

   .right-j {
      padding:2rem;
   }
 }